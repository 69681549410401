import {
    IDashboardPage,
    IDashboardParametersBase,
    IExternalPlugin,
    IPluginConfig,
    IPluginFieldValueBase,
    IPluginSettingPage,
    IProjectSettingsBase,
    IServerSettingsBase,
    ITool,
} from "../../../sdk/plugins/interfaces";
import {
    IItem,
    PluginCore,
    globalMatrix,
    matrixsdk,
    INotificationConfig,
    notificationSetting,
    defaultNotificationConfig,
    app,
} from "../../../sdk/client";
import { ControlCoreBase, IPluginFieldHandler } from "../../../sdk/plugins/ControlCore";
import { DashboardPage } from "./DashboardPage";
import { IProjectSettings, IServerSettings } from "./Interfaces";
import { Project } from "../../../sdk/objects/Project";
export class Plugin
    implements
        IExternalPlugin<
            IServerSettingsBase,
            IProjectSettings,
            IPluginFieldHandler<IPluginFieldValueBase>,
            IPluginFieldValueBase,
            IDashboardParametersBase
        >
{
    core: PluginCore;
    name = "EXPORT";
    /**This part enables which
     *
     * See IPluginConfig interface for explanation of parameters
     */

    static config: IPluginConfig<IServerSettings, IProjectSettings> = {
        /*  Page in admin client to configure settings across all projects - set enabled to false if not needed.
            The page itself is implemented in the _ServerSettingsPage.ts
        */
        customerSettingsPage: {
            id: "EXPORTCustomerSettings",
            title: "Export",
            type: "EXPORTcs",
            enabled: false,
            defaultSettings: defaultNotificationConfig,
            settingName: "settingsNotification",
            help: "",
            helpUrl: "https://urlshort.matrixreq.com/d25/",
        },
        /*  Page in admin client to configure settings for one specific project - set enabled to false if not needed.
            The page itself is implemented in the _ProjectSetingsPage.ts
        */
        projectSettingsPage: {
            id: "exportConfig",
            title: "Export Configuration",
            type: "exportConfigts",
            enabled: false,
            defaultSettings: {},
            settingName: "export_config",
            help: "",
            helpUrl: undefined,
        },
        /*  Add an entry in the tool menu of an item or folder - set enabled to false if not needed.
            The tool itself is implemented in the _Tool.ts
        */
        menuToolItem: {
            enabled: false,
            title: "Notifications overview",
        },
        /*  Add a custom field to enter some data in the UI - set enabled to false if not needed.
            The field itself is implemented in the _Control.ts
        */
        field: {
            enabled: false,
            fieldType: "Export",
            title: "Export",
            fieldConfigOptions: {
                id: "Export",
                capabilities: {
                    canBePublished: false,
                    canBeReadonly: true,
                    canBeXtcPreset: false,
                    canHideInDoc: false,
                    canBeUsedInDocs: false,
                    canRequireContent: false,
                },
                class: "",
                help: "",
                label: "Export",
            },
        },
        /*  Add a dashboard inside a project - set enabled to false if not needed.
            The field itself is implemented in the _Control.ts
        */
        dashboard: {
            id: "EXPORT",
            title: "Export",
            enabled: true,
            icon: "far  fa-download",
            parent: "TOOLS",
            usefilter: true,
            order: 9999,
        },
    };

    /**
     * The constructor is loaded once after all the source code is loaded by the browser.
     * Nothing is known about the instance, project, user etc.
     * You can use it to initialize things like callbacks after item changes
     */
    constructor() {
        // here is a good place to register callbacks for UI events (like displaying or saving items)
        this.core = new PluginCore(this);
    }

    PLUGIN_VERSION = app.getVersion();
    PLUGIN_NAME = "EXPORT";

    private currentProject: Project | null = null;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async setupProject(newProjectName?: string) {
        if (this.currentProject) {
            // Did we change projects?
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (newProjectName && this.currentProject.getName() != newProjectName) {
                this.currentProject = null;
            }
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.currentProject == null) {
            this.currentProject = newProjectName
                ? await matrixsdk.openProject(newProjectName)
                : await matrixsdk.openCurrentProjectFromSession();
        }
    }

    async getDashboardAsync(): Promise<IDashboardPage<IDashboardParametersBase>> {
        // Whoa, now is my chance to load from the web
        // I can do "slow" things here if necessary.
        await this.setupProject();
        // TODO: projectStorage should be available on Project.
        // TODO: remove type assertion, it's clearly not safe
        return new DashboardPage(this.currentProject as Project, globalMatrix.projectStorage);
    }
    async getProjectSettingsPageAsync(): Promise<IPluginSettingPage<IProjectSettingsBase>> {
        // @ts-ignore IExternalPlugin needs to be updated
        return null;
    }
    async getServerSettingsPageAsync(): Promise<IPluginSettingPage<IServerSettingsBase>> {
        await this.setupProject();

        // @ts-ignore IExternalPlugin needs to be updated
        return null;
    }
    async getControlAsync(
        ctrlObj: JQuery,
    ): Promise<ControlCoreBase<IPluginFieldHandler<IPluginFieldValueBase>, IPluginFieldValueBase>> {
        await this.setupProject();
        // @ts-ignore IExternalPlugin needs to be updated
        return null;
    }
    async getToolAsync(): Promise<ITool> {
        // @ts-ignore IExternalPlugin needs to be updated
        return null;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getConfig() {
        return Plugin.config;
    }
    enableToolMenu(ul: JQuery, _hook: number): boolean {
        return this.core.enabledInContext;
    }
    /**
     * This method is called each time  a project has been loaded and initialized.
     * At the time it is called, all project settings, categories etc are defined.
     *
     * @param project // id of the loaded project
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onInitProject(project: string) {
        // here is a good place to decide based on the project (maybe some project setting), whether the plugin should be enabled
        // if not:
        // this.enabledInContext = false;
    }

    /** this method is called just before the rendering of an item is done
     * It is also called when opening the create item dialog.
     *
     * @param item: the item which is being loaded in the UI
     */
    lastProject = "";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onInitItem(item: IItem) {
        //Note: this method is called each time an item is loaded in the UI
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function initialize() {
    // register the engine as plugin
    // @ts-ignore TODO: remove global
    globalThis.plugins.register(new Plugin().core);
}

import { initialize as InitializeExport } from "./dashboards/EXPORT/Main";

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initializeDashboards() {
    console.log("Initializing dashboard");
    InitializeExport();
}

initializeDashboards();
